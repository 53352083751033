import React, { useState, useEffect, useRef } from "react";
import './Works.css';
import WorkItem from "./WorkItem.jsx";
import Modal from "./Modal.jsx";

function Works() {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedWork, setSelectedWork] = useState(null);
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false);
    const titleRef = useRef(null);

    const workItems = [
        {
            id: 1,
            title: '合同会社ensia様',
            image: './images/ensia.jpg',
            description: 'ライバー事務所のホームページ制作。ライブ配信のキラキラしたイメージ。コンテンツは今後追加更新予定',
            language: ['HTML', 'CSS', 'WordPress'],
            detailsImage: './images/ensia-ss.png'
        },
        {
            id: 2,
            title: 'インフルエンサー向け動画制作会社様',
            image: './images/amusementlive.png',
            description: 'インフルエンサー向け動画制作会社のホームページ制作。',
            language: ['HTML', 'CSS', 'WordPress'],
            detailsImage: './images/amusementlive.png',
        },
        {
            id: 3,
            title: '株式会社浜屋様',
            image: './images/hamaya-corp.png',
            description: '中古品の買取や販売を主に行っている会社のLP制作。「MOTTAINAIを広めよう」をキーワードに世界中でお取引を行っていることがわかるよう、実際に笑顔になっている海外の方々の写真を使用しました。色合いはコーポレートカラーの黄色をメインで使用しております。',
            language: ['STUDIO'],
            detailsImage: './images/hamaya-corp_lp.png',
        },
        {
            id: 4,
            title: 'ヘルパーステーションぷらす様',
            image: './images/helperstation.png',
            description: 'ホームヘルパー事業者様のホームページ制作。',
            language: ['HTML', 'CSS', 'WordPress'],
            detailsImage: './images/helperstationplus-ss.png'
        },
        {
            id: 5,
            title: 'GARPOアニメーション様',
            image: './images/garpo-animation.jpg',
            description: 'アニメーション動画制作サービス"記事アニ"・LP制作',
            language: ['HTML', 'CSS', 'WordPress', 'PHP'],
            detailsImage: './images/garpo-animation-kiziani-lp.png'
        },
        {
            id: 6,
            title: '株式会社トーカイスパイラル様',
            image: './images/tospa_lp.jpg',
            description: '主に配管工事を行っている会社の局所排気装置の点検や清掃を行う事業部のLP制作。青ベースのメンテナンスや清掃のクリーンなイメージのデザインに。局所排気装置の点検は法律で義務付けられていることなどユーザーが知るべき情報を載せています。',
            language: ['HTML', 'CSS', 'WordPress'],
            detailsImage: './images/tospa_lp.jpg'
        },
        {
            id: 7,
            title: 'ynmarke(React開発)',
            image: './images/ynmarke-React.jpg',
            description: '本サイト・Reactをメインに開発。',
            language: ['React', 'HTML', 'CSS', 'JavaScript'],
            detailsImage: './images/ynmarke-React-ss.png'
        },
        {
            id: 8,
            title: 'ynmarke(WordPress)',
            image: './images/ynmarke.jpg',
            description: '個人開発・WordPress構築サイト',
            language: ['HTML', 'CSS', 'WordPress', 'PHP'],
            detailsImage: './images/ynmarke-ss.png'
        },
        {
            id: 9,
            title: 'ynmarke(STUDIO）',
            image: './images/ynmarkelp.jpg',
            description: 'LP個人開発',
            language: ['STUDIO'],
            detailsImage: './images/ynmarke-studio-ss.png'
        },
    ];

    const handleWorkItemClick = (work) => {
        setSelectedWork(work);
        setModalVisible(true);

    };

    const handleModalClose = () => {
        setModalVisible(false);
    };


    useEffect(() => {
        const currentTitleRef = titleRef.current;  // <- copy 'titleRef.current' to a variable
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasSlideAnimated) {
                    setHasSlideAnimated(true);
                }
            });
        });

        if (currentTitleRef) {
            observer.observe(currentTitleRef);

            // use the variable in the cleanup function
            return () => observer.unobserve(currentTitleRef);
        }
    }, [hasSlideAnimated]); // <- include 'hasSlideAnimated' in the dependencies array


    return (
        <div i="works" className='works'>
            <h2 className={`section-title ${hasSlideAnimated ? 'active' : ''}`} ref={titleRef}>Works</h2>

            {workItems.map((work) => (
                <WorkItem
                    key={work.id}
                    work={work}
                    onWorkItemClick={handleWorkItemClick}
                />
            ))}

            {modalVisible && (
                <Modal
                    show={modalVisible}
                    onClose={handleModalClose}
                >
                    <h2>{selectedWork.title}</h2>
                    <p>{selectedWork.description}</p>
                    <img src={selectedWork.image} alt={selectedWork.title} />
                </Modal>
            )}
        </div>

    );
}

export default Works;