import React, { useState, useEffect, useRef } from 'react';
import './Concept.css';

const Concept = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false); // 新たなステートを追加
    const titleRef = useRef(null);
    const domRef = useRef(null);

    const conceptData = {
        title: "Concept",
        image: "./images/webfreelance.jpg",
        subTitle: "「やってみたいこと」をWebの力で実現します！",
        text: "ynmarke（ワイエヌマーケ）は、フリーランスエンジニアです。中小企業様や起業して間もない方の活動を支えるべく、ホームページ制作、web開発やwebマーケティング事業を行っています。ホームページは名刺のようなものです。人それぞれやりたいことや、誰かの悩みを解決したいなどの想いがあり、その延長線上にビジネスという選択肢があるのかと思います。それらの想いをホームページ上で表現するべく日々制作業務をしております。ぜひ実現したいことや、疑問点や不安な点などがあればぜひお話いただき、一緒に良いものを作っていけたらと思います。"
    };


    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasAnimated) {
                    setIsVisible(true);
                    setHasAnimated(true);
                }
                if (entry.isIntersecting && !hasSlideAnimated) {
                    setHasSlideAnimated(true);
                }
            });
        });

        const currentTitleRef = titleRef.current;
        const currentDomRef = domRef.current;

        if (currentTitleRef) {
            observer.observe(currentTitleRef);
        }

        if (currentDomRef) {
            observer.observe(currentDomRef);
        }

        return () => {
            if (currentTitleRef) observer.unobserve(currentTitleRef);
            if (currentDomRef) observer.unobserve(currentDomRef);
        };
    }, [hasAnimated, hasSlideAnimated]);


    return (
        <div id="concept" className='concept'>
            <h2 className={`section-title-l ${hasSlideAnimated ? 'active' : ''}`} ref={titleRef}>Concept</h2>
            <div className='concept-container'>

                <div className='concept-left'>
                    <div className={`cover-slide ${hasSlideAnimated ? 'active' : ''}`}>
                        <div className="image-container">
                            <img className={`concept-image ${isVisible ? 'active' : ''}`} src={conceptData.image} alt={conceptData.title} />
                        </div>
                    </div>
                </div>
                <div className='concept-right'>
                    <h3>{conceptData.subTitle}</h3>
                    <p>{conceptData.text}</p>
                </div>
            </div>
        </div>

    );
}

export default Concept;
