import React from 'react';
import './Contact.css';

const Contact = () => {
    // const [step, setStep] = useState(1);
    // const [name, setName] = useState("");
    // const [company, setCompany] = useState("");
    // const [email, setEmail] = useState("");
    // const [confirmEmail, setConfirmEmail] = useState("");
    // const [message, setMessage] = useState("");
    // const [emailMatch, setEmailMatch] = useState(true);

    // const formRef = useRef(null);

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     if (step === 1) {
    //         setStep(2);
    //     } else if (step === 2) {
    //         const data = new FormData();
    //         data.append('name', name);
    //         data.append('company', company);
    //         data.append('email', email);
    //         data.append('confirmEmail', confirmEmail);
    //         data.append('message', message);

    //         fetch('https://developer.ynmarketing.jp/PHP/sendEmail.php', {
    //             method: 'POST',
    //             body: data,
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 if (data.success) {
    //                     setStep(3);
    //                 } else {
    //                     alert('There was an error sending your message. Please try again.');
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error:', error);
    //                 alert('There was an error sending your message. Please try again.');
    //             });
    //     }
    // };

    // const handleEmailChange = (e) => {
    //     setEmail(e.target.value);
    //     setEmailMatch(e.target.value === confirmEmail);
    // };

    // const handleConfirmEmailChange = (e) => {
    //     setConfirmEmail(e.target.value);
    //     setEmailMatch(email === e.target.value);
    // };

    return (
        <div id="contact" className="contact">
            {/* <h2>Contact</h2> */}
            {/* {step === 1 && (
                <form ref={formRef} onSubmit={handleSubmit}>
                    <label>
                        お名前（必須）
                        <input type="text" value={name} onChange={e => setName(e.target.value)} required />
                    </label>
                    <label>
                        会社名
                        <input type="text" value={company} onChange={e => setCompany(e.target.value)} />
                    </label>
                    <label>
                        メールアドレス（必須）
                        <input type="email" value={email} onChange={handleEmailChange} required />
                    </label>
                    <label>
                        メールアドレス確認（必須）
                        <input type="email" value={confirmEmail} onChange={handleConfirmEmailChange} required />
                        {!emailMatch && <p style={{ color: 'red' }}>メールアドレスが一致しません。</p>}
                    </label>
                    <label>
                        お問い合わせ内容
                        <textarea value={message} onChange={e => setMessage(e.target.value)} />
                    </label>
                    <button type="submit" disabled={!emailMatch}>内容を確認する</button>
                </form>
            )}
            {step === 2 && (
                <div className='confirm'>
                    <p>お名前: {name}</p>
                    <p>会社名: {company}</p>
                    <p>メールアドレス: {email}</p>
                    <p>お問い合わせ内容: {message}</p>
                    <button onClick={() => setStep(1)}>戻る</button>
                    <button onClick={handleSubmit}>送信する</button>
                </div>
            )}
            {step === 3 && (
                <div>
                    <p>お問い合わせありがとうございます。内容を確認し、2～3日以内にご返信させていただきます。</p>
                </div>
            )} */}

            <div style={{ position: 'relative', height: "990px", paddingBottom: '56.25%', overflow: 'hidden' }}>
                <iframe title="My Frame" src="https://ynmarketing.jp/script/mailform/contact/" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0 }}></iframe>
            </div>

        </div >
    );
}

export default Contact;
