import React, { useState, useEffect, useRef } from 'react';
import './Service.css';
import ContactButton from './ContactButton';

const Service = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false); // 新たなステートを追加
    const titleRef = useRef(null);
    const domRef = useRef(null);

    const serviceData = {
        title: "Service",
        image: "./images/service.jpg",
        subTitle: "ホームページ制作（業種問わずOK！）",
        points: ["HTML＆CSS", "WordPress", "STUDIO＆WiX（ノーコード）", "その他開発言語"],
        text: "ホームページは作って終わりではありません。制作後のSEO対策、MEO対策、リスティング広告運用など運用面でのサポートもいたします。"
    };


    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasAnimated) {
                    setIsVisible(true);
                    setHasAnimated(true);
                }
                if (entry.isIntersecting && !hasSlideAnimated) {
                    setHasSlideAnimated(true);
                }
            });
        });

        const currentTitleRef = titleRef.current;
        const currentDomRef = domRef.current;

        if (currentTitleRef) {
            observer.observe(currentTitleRef);
        }

        if (currentDomRef) {
            observer.observe(currentDomRef);
        }

        return () => {
            if (currentTitleRef) observer.unobserve(currentTitleRef);
            if (currentDomRef) observer.unobserve(currentDomRef);
        };
    }, [hasAnimated, hasSlideAnimated]);


    return (
        <div id="service" className='service'>
            <h2 className={`section-title-l ${hasSlideAnimated ? 'active' : ''}`} ref={titleRef}>Service</h2>
            <div className='service-container'>

                <div className='service-left'>
                    <h3>{serviceData.subTitle}</h3>
                    <ul>
                        {serviceData.points.map((point, index) =>
                            <li key={index}>{point}</li>
                        )}
                    </ul>
                    <p>{serviceData.text}</p>
                    <ContactButton />
                </div>
                <div className='service-right'>
                    <div className={`cover-slide ${hasSlideAnimated ? 'active' : ''}`}>
                        <div className="image-container">
                            <img className={`service-image ${isVisible ? 'active' : ''}`} src={serviceData.image} alt={serviceData.title} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


export default Service;