import React from 'react';
import './ContactButton.css';

// ContactButton コンポーネント
const ContactButton = () => {
    return (
        <div className="ContactButton">
            <a href="#contact" className="free-Contact-button">
                無料相談する
            </a>
        </div>
    );
}

export default ContactButton;
