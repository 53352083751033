import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="profile">
                <div className="profileItem">事業形態：<span>フリーランス</span></div>
                <div className="profileItem">屋号：<span>ynmarke（ワイエヌマーケ）</span></div>
                <div className="profileItem">代表：<span>和田 祐紀</span></div>
                <div className="profileItem">事業内容：<span>web制作及び開発/webマーケティング</span></div>
                <div className="profileItem">定休日：<span>不定休</span></div>
            </div>
            <div className="copyright">
                <p>ホームページ制作・web開発</p>
                <p>東京都|フリーランスエンジニア</p>
                <p>© ynmarke All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;