import React from 'react';
import './MainVisual.css';

function MainVisual() {
    return (
        <div className="main-visual">
            <div className="slideBackgraund1">
                <h1 className="title1">抱えている悩みや現状の課題を</h1>
            </div>
            <div className="slideBackgraund2">
                <h1 className="title2">Webの力で解決します！</h1>
            </div>

            <h2 className="title3">I will solve your problems on the Web！</h2>

        </div>
    );
}

export default MainVisual;