import React, { useEffect, useRef, useState } from 'react';
import './Skills.css';

const Skills = () => {
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false);
    const titleRef = useRef(null);

    const skillsData = [
        {
            category: "フロントエンド（Vueは習得予定）",
            languages: ["HTML", "CSS", "JavaScript", "React", "Vue"]
        },
        {
            category: "バックエンド・データベース(共に習得中)",
            languages: ["PHP", "Node", "SQL"]
        },
    ];

    const flatSkillsData = skillsData.reduce((acc, curr) => acc.concat(curr.languages), []);

    const languageRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in-up');
                }
            });
        });

        languageRefs.current.forEach(ref => {
            observer.observe(ref);
        });

        const currentRefs = languageRefs.current;

        return () => {
            if (currentRefs) {
                currentRefs.forEach(ref => {
                    observer.unobserve(ref);
                });
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setHasSlideAnimated(true);
                }
            });
        });

        const currentTitleRef = titleRef.current;

        if (currentTitleRef) {
            observer.observe(currentTitleRef);
        }

        return () => {
            if (currentTitleRef) observer.unobserve(currentTitleRef);
        };
    }, []);

    return (
        <div id="skills" className='skills'>
            <h2 className={`section-title ${hasSlideAnimated ? 'active' : ''}`} ref={titleRef}>Skills</h2>
            {skillsData.map((skill, index) => (
                <div key={index} className='skill-category'>
                    <h3>{skill.category}</h3>
                    <div className='languages'>
                        {skill.languages.map((language, innerIndex) => (
                            <div key={innerIndex} className='language'
                                ref={el => languageRefs.current[flatSkillsData.indexOf(language)] = el}>
                                <img src={`./images/${language}.png`} alt={language} />
                                <h4>{language}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Skills;
