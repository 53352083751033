import React from 'react';
import Header from './components/Header.jsx';
import MainVisual from './components/MainVisual.jsx';
import Works from './components/Works/index.jsx';
import Concept from './components/Concept.jsx';
import Service from './components/Service.jsx';
import Skill from './components/Skills.jsx';
import Price from './components/Price.jsx';
import Flow from './components/Flow.jsx';
import Contact from './components/Contact.jsx';
import Footer from './components/Footer.jsx';

function App() {
  return (
    <div className="App">
      <Header />
      <MainVisual />
      <Works />
      <Concept />
      <Service />
      <Skill />
      <Price />
      <Flow />
      <Contact />
      <Footer />
    </div>
  );
}


export default App;