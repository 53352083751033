import React, { useState, useEffect, useRef } from 'react';
import Modal from './Modal.jsx';
import './Works.css';


const WorkItem = ({ work }) => {
    const [showModal, setShowModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false); // 新たなステートを追加
    const domRef = useRef();

    const openModal = () => {
        document.body.classList.add('no-scroll');
        setShowModal(true);
    };

    const closeModal = () => {
        document.body.classList.remove('no-scroll');
        setShowModal(false);
    };

    useEffect(() => {
        const currentRef = domRef.current;
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !hasAnimated) {
                    setIsVisible(true);
                    setHasAnimated(true);
                }
                if (entry.isIntersecting && !hasSlideAnimated) { // kf-cover-slideのアニメーションがまだ実行されていない場合のみ実行
                    setHasSlideAnimated(true); // アニメーションが実行されたことを記録
                }
            });
        });

        if (currentRef) {
            observer.observe(currentRef);

            return () => observer.unobserve(currentRef);
        }
    }, [hasAnimated, hasSlideAnimated]);


    return (
        <>
            <div className="work-item" onClick={openModal} ref={domRef}>
                <div className={`cover-slide ${hasSlideAnimated ? 'active' : ''}`}>
                    <img className={`work-item-image ${isVisible ? 'active' : ''}`} src={work.image} alt={work.title} />
                </div>
                <div className="languages">
                    {work.language.map((lang, index) => (
                        <p key={index} className={`language-badge language-${lang}`}>{lang}</p>
                    ))}
                </div>
                <h3 className="title">{work.title}</h3>
            </div>

            <Modal show={showModal} onClose={closeModal}>
                <div className='modal-contentBox'>
                    <div className='modal-inner'>
                        <div className='modal-left'>
                            <img src={work.detailsImage} alt={`${work.title} Details`} />
                        </div>
                        <div className='modal-right'>
                            <h2>{work.title}</h2>
                            <div className="workslanguages">
                                {work.language.map((lang, index) => (
                                    <p key={index} className={`language-badge language-${lang}`}>{lang}</p>
                                ))}
                            </div>
                            <p>{work.description}</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default WorkItem;
