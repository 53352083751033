import React, { useState, useEffect, useRef } from 'react';
import './Flow.css';

const Flow = () => {
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false);
    const titleRef = useRef(null);

    const steps = [
        { step: "STEP1", schedule: "全体納期：およそ1ヶ月", title: "お問い合わせ", text: "お問い合わせフォームよりお問い合わせをお願いいたします。" },
        { step: "STEP2", schedule: "目安：1～2h", title: "ヒアリング", text: "HPを制作する目的、事業内容やターゲットをヒアリングさせていただきます。" },
        { step: "STEP3", schedule: "目安：1～2日程", title: "見積り提案", text: "ヒアリングした内容を元に見積りをご提案いたします。" },
        { step: "STEP4", schedule: "", title: "ご契約・ご入金", text: "内容にご了承いただけましたらご契約になります。ご契約時に指定の口座に制作料金の全額、または50％をご入金いただきます。" },
        { step: "STEP5", schedule: "目安：1週間程", title: "デザイン", text: "デザイン案を作成してご確認いただきます。必要に応じてご相談をしながら進めます。" },
        { step: "STEP6", schedule: "目安：1週間程", title: "コーディング", text: "デザインが決まりましたら、サイトの実装に入ります。" },
        { step: "STEP7", schedule: "", title: "修正・納品", text: "最終チェックを行い、問題がなければ、データでの納品かお客様のサーバーでの公開作業を行います。使い方もご説明させていただきますのでご安心ください。" },
        { step: "STEP8", schedule: "", title: "最終ご入金", text: "ご契約時に制作料金の50％をご入金いただいた場合は、納品後指定の期日までに残りの50％のご入金をしていただきます。" },
    ];

    // STEP要素用のrefの配列を定義
    const stepRefs = useRef([]);

    // ...

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in-up');
                }
            });
        });

        // 各ステップに対してobserverを設定
        stepRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });

        const currentRefs = stepRefs.current;

        return () => {
            if (currentRefs) {
                currentRefs.forEach(ref => {
                    if (ref) observer.unobserve(ref);
                });
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setHasSlideAnimated(true);
                }
            });
        });

        const currentTitleRef = titleRef.current;

        if (currentTitleRef) {
            observer.observe(currentTitleRef);
        }

        return () => {
            if (currentTitleRef) observer.unobserve(currentTitleRef);
        };
    }, []);

    return (
        <div id="flow" className="flow">
            <h2 className={`section-title ${hasSlideAnimated ? 'active' : ''}`} ref={titleRef}>Flow</h2>
            <div className="steps">
                {steps.map((step, index) => (
                    <div key={index} className="step" ref={ref => stepRefs.current[index] = ref}>
                        <div className="step-arrow">{step.step}</div>
                        <h3>{step.title}</h3>
                        <p>{step.text}</p>
                        <p>{step.schedule}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Flow;
