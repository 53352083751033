import React, { useState, useEffect, useRef } from 'react';
import './Price.css';
import ContactButton from './ContactButton';

const Price = () => {
    const [hasSlideAnimated, setHasSlideAnimated] = useState(false);
    const titleRef = useRef(null);

    const priceData = [
        {
            plan: "ライトプラン（税込）",
            price: "¥99,000",
            description: "少ない予算でしっかりとしたHPが欲しい方。",
            details: {
                "ページ数": "1ページ",
                "納期の目安": "2～3週間",
                "デザイン": "オリジナル",
                "スマホ対応": "○",
                "お問い合わせフォーム": "○",
                "開発環境": "HTML＆CSSまたはWordPress"
            },
            explanation: `少ない予算でしっかりとしたホームページが欲しい方におすすめです。
            ・プラン料金にドメイン、サーバー代は含まれておりません。
            ・ページ数や内容量によって金額は多少前後します。
            ・テンプレートを使用の場合、料金の割引や納期の短縮をいたします。`
        },
        {
            plan: "スタンダードプラン（税込）",
            price: "¥198,000",
            description: "標準的な規模のHPが欲しい方。",
            details: {
                "ページ数": "5ページ",
                "納期の目安": "3～4週間",
                "デザイン": "オリジナル",
                "スマホ対応": "○",
                "お問い合わせフォーム": "○",
                "開発環境": "HTML＆CSS,WordPressまたは別言語"
            },
            explanation: `標準的な規模のホームページが欲しい方におすすめです。
            ・プラン料金にドメイン、サーバー代は含まれておりません。
            ・ページ数や内容量によって金額は多少前後します。
            ・テンプレートを使用の場合、料金の割引や納期の短縮をいたします。`
        },
        {
            plan: "プレミアムプラン（税込）",
            price: "¥297,000～",
            description: "大規模なHPが欲しい方。",
            details: {
                "ページ数": "10ページ以上",
                "納期の目安": "4～8週間",
                "デザイン": "オリジナル",
                "スマホ対応": "○",
                "お問い合わせフォーム": "○",
                "開発環境": "HTML＆CSS,WordPressまたは別言語"
            },
            explanation: `大規模なホームページが欲しい方におすすめです。
            ・プラン料金にドメイン、サーバー代は含まれておりません。
            ・ページ数や内容量によって金額は多少前後します。`
        }
    ];

    const [activePlan, setActivePlan] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setHasSlideAnimated(true);
                }
            });
        });

        const currentTitleRef = titleRef.current;

        if (currentTitleRef) {
            observer.observe(currentTitleRef);
        }

        return () => {
            if (currentTitleRef) observer.unobserve(currentTitleRef);
        };
    }, []);


    return (
        <div id="price" className='price'>
            <h2 className={`section-title ${hasSlideAnimated ? 'active' : ''}`} ref={titleRef}>Price</h2>
            <div className='pricing-cards'>
                {priceData.map((price, index) => (
                    <div
                        key={index}
                        className={`pricing-card ${activePlan === index ? 'active' : ''}`}
                        onClick={() => setActivePlan(index)}
                    >
                        <h3>{price.plan}</h3>
                        <p>{price.price}</p>
                        <p>{price.description}</p>
                    </div>
                ))}
            </div>
            {activePlan !== null && (
                <div className='plan-details'>
                    <h3>プラン詳細</h3>
                    <table>
                        <tbody>
                            {Object.entries(priceData[activePlan].details).map(([key, value], index) => (
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <p>
                        {priceData[activePlan].explanation.split('\n').map((line, i) => (
                            <span key={i}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </p>
                </div>
            )}
            <div className='comparison'>
                <h3>プラン内容比較表</h3>
                <div className='comparison-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>内容</th>
                                {priceData.map((price, index) => (
                                    <th key={index}>{price.plan}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(priceData[0].details).map((key, index) => (
                                <tr key={index}>
                                    <td>{key}</td>
                                    {priceData.map((price, priceIndex) => (
                                        <td key={priceIndex}>{price.details[key]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <ContactButton />
        </div >
    );
}

export default Price;
