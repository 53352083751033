import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link as ScrollLink } from 'react-scroll';

function Header() {
    const [menuOpen, setMenuOpen] = useState(window.innerWidth >= 768);

    const checkMenu = () => {
        if (window.innerWidth >= 768) {
            setMenuOpen(true);
        } else {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', checkMenu);
        return () => {
            window.removeEventListener('resize', checkMenu);
        };
    }, []);

    return (
        <header>
            <div className="logo">ynmarke</div>

            <div onClick={() => setMenuOpen(!menuOpen)} className={`menu-icon ${menuOpen ? 'menuOpen' : ''}`}>
                <div className='menu'></div>
                <div className='menu'></div>
                <div className='menu'></div>
            </div>

            <nav className={menuOpen ? "open" : ""}>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='works'>Works</ScrollLink></div>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='concept'>Concept</ScrollLink></div>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='service'>Service</ScrollLink></div>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='skills'>Skills</ScrollLink></div>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='price'>Price</ScrollLink></div>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='flow'>Flow</ScrollLink></div>
                <div><ScrollLink onClick={() => { if (window.innerWidth < 768) setMenuOpen(false) }} className="navlink" smooth={true} duration={500} to='contact'>Contact</ScrollLink></div>
            </nav>
        </header>
    );
}

export default Header;
